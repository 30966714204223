import { useEffect } from 'react';

import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';
import { useSetRecoilState } from 'recoil';

import { authState } from '../stores/auth';

const AuthContext = ({ children }) => {
  const { loading, isLoggedIn, accessToken } = useAuthInfo();
  const setAuthState = useSetRecoilState(authState);
  const { redirectToLoginPage } = useRedirectFunctions();

  useEffect(() => {
    setAuthState({ accessToken: accessToken || '' });
  }, [accessToken, setAuthState]);

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    redirectToLoginPage({
      userSignupQueryParameters: { opt_hint: 'pw,sso' },
      postLoginRedirectUrl: window.location.href,
    });
    return null;
  }

  return <>{children}</>;
};

export default AuthContext;
