import React, { useEffect } from 'react';

import { Button, Grid, styled, Typography, Link, Stack, Card, useTheme, Box, Chip } from '@mui/joy';
import { CaretLeftIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Image from '../../../components/Image';
import { track } from '../../../services/analytics';
import {
  EVENT_RESOURCE_PAGE,
  PGV_resource_detail,
  PGVAPP_resource_detail_download_btn_click,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { getAssetFileUrl, getAssetURL, resources } from '../../../services/axios/endpoints';
import { RESOURCE_TYPE } from '../../../utils/constants';
import { dateFormat } from '../../../utils/functions/date';
import { capitalizeFirstLetter } from '../../../utils/functions/string';
import { NormaliseCSSWrapper } from '../../project/ghg_rating/components/HTMLRenderer';

import ResourceDescriptionLoader from './skeletons/ResourceDescriptionLoader';

interface tag {
  id: string;
  tag: string;
}
interface tags {
  tag_info: tag;
}

const Tbody = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: theme.vars.palette.text.primary,
  fontFamily: theme?.fontFamily?.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: theme.vars.lineHeight.md,
  ul: {
    paddingLeft: '32px',
    li: {
      listStyle: 'disc',
    },
  },
  '.RichText3-paragraph': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: theme.vars.lineHeight.md,
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
      textDecoration: 'underline',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    maxWidth: '750px',
    borderRadius: '24px',
  },
  '& > h5': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: theme.vars.lineHeight.md,
  },
  '& > p': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: theme.vars.lineHeight.md,
    wordWrap: 'break-word',
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
      textDecoration: 'underline',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
    '& > i': {
      fontStyle: 'italic',
    },
  },
  '& > a': {
    color: theme.vars.palette['hyperlink']['text'],
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
    textDecoration: 'underline',
  },
  '& > div': {
    '& > table': {
      ' & > tr': {
        '& > th': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
        '& > td': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
      },
    },
  },
  '& > ol': {
    listStyle: 'auto',
    paddingLeft: theme.spacing(4),
  },
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: theme.vars.radius.lg,
}));

export const ResourceDescription = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const id = params.get('tag');
  const { data, isLoading } = useQuery({
    queryKey: ['blogs', { id }],
    queryFn: () => {
      return makeAuthenticatedGetRequest(`${resources}/${id}`);
    },
    select: (data) => data?.data?.response,
  });

  const theme = useTheme();

  const handleDownloadResource = () => {
    track(PGVAPP_resource_detail_download_btn_click, EVENT_RESOURCE_PAGE, {
      page_url: `${location.pathname}${location.search}`,
      title: data?.title,
    });
  };

  useEffect(() => {
    if (!!data?.title) {
      track(PGV_resource_detail, EVENT_RESOURCE_PAGE, { title: data?.title });
    }
  }, [data]);

  return (
    <Box
      sx={{
        paddingY: 5,
        mx: 'auto',
        maxWidth: 'lg',
      }}
    >
      <Grid
        container
        xs={12}
        mx={'auto'}
        gap={3}
        justifyContent={'flex-start'}
        direction={'column'}
        alignItems={'center'}
        sx={{ height: '100%', minHeight: '100vh' }}
      >
        <Stack alignItems={'flex-start'} width={'100%'} paddingX={5}>
          <Button
            startDecorator={<CaretLeftIcon style={{ fontSize: theme.vars.fontSize.xl2 }} />}
            variant='plain'
            onClick={() => {
              navigate(-1);
            }}
          >
            <Typography fontSize={'sm'} fontWeight={'sm'} color='primary'>
              Return to resource page
            </Typography>
          </Button>
        </Stack>
        {!isLoading ? (
          <React.Fragment>
            <Card
              sx={{
                width: 'fit-content',
                marginX: 5,
              }}
            >
              <React.Fragment>
                <Stack gap={1} direction={'row'} display={'flex'}>
                  {data?.tags?.map((tag: tags) => {
                    return (
                      <Chip
                        variant='outlined'
                        color='neutral'
                        sx={{
                          padding: '0px 8px',
                        }}
                      >
                        <Typography fontWeight={'md'} fontSize={'md'}>
                          {capitalizeFirstLetter(tag?.tag_info?.tag)}
                        </Typography>
                      </Chip>
                    );
                  })}
                </Stack>
                <Typography level='h1' fontWeight={'md'} fontSize={'3rem'}>
                  {data?.title}
                </Typography>
                <Typography level='h2' fontWeight={'md'} fontSize={'xl'}>
                  {dateFormat(data?.date).mmddyyyy}
                </Typography>
                <Grid container gap={'16px'} alignItems={'center'}>
                  <Grid xs={12} lg={5.9}>
                    <StyledImage src={getAssetURL(data?.visual, RESOURCE_TYPE)} alt={data?.title} />
                  </Grid>
                  <Grid xs={12} lg={5.9}>
                    <Stack>
                      <NormaliseCSSWrapper>
                        <Tbody dangerouslySetInnerHTML={{ __html: data?.post }} />
                      </NormaliseCSSWrapper>
                    </Stack>
                    {data?.file && (
                      <Stack width={'fit-content'}>
                        <Link
                          onClick={handleDownloadResource}
                          target='_blank'
                          href={getAssetFileUrl(data?.file)}
                          sx={{
                            textDecoration: 'none !important',
                          }}
                        >
                          <Button
                            sx={{
                              px: 3,
                              py: 1,
                              minHeight: '3rem',
                              fontWeight: theme.vars.fontWeight.sm,
                              mt: 3,
                            }}
                          >
                            Download Resource
                          </Button>
                        </Link>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            </Card>
          </React.Fragment>
        ) : (
          <ResourceDescriptionLoader />
        )}
      </Grid>
    </Box>
  );
};
